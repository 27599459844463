export const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();

	return (
		<footer className="bg-white py-5 md:py-10 shadow">
			<div className="container max-w-7xl mx-auto px-5">
				&copy; {year} Digitellie Web Development LLC. All rights reserved.
			</div>
		</footer>
	);
};