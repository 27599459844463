export const Row = ({
	alignItems,
	children,
	cols,
	colsMd,
	gap,
	justifyContent,
	props
}) => {
	const classList = ['grid'];

	switch (alignItems) {
		case 'center':
			classList.push('items-center');
			break;

		case 'end':
			classList.push('items-end');
			break;

		case 'start':
			classList.push('items-start');
			break;
		
		default:
			break;
	}

	switch (colsMd) {
		case '3':
			classList.push('md:grid-cols-3');
			break;

		default:
			break;
	}

	switch (gap) {
		case 'small':
			break;

		case 'medium':
			break;

		case 'large':
			classList.push('gap-6');
			break;

		default:
			break;
	}

	switch (justifyContent) {
		case 'center':
			classList.push('justify-center');
			break;

		case 'end':
			classList.push('justify-end');
			break;

		case 'start':
			classList.push('justify-start');
			break;
		
		default:
			break;
	}

	return (
		<div className={classList.join(' ')} {...props}>
			{children}
		</div>
	);	
};

Row.defaultProps = {
	gap: 'large'
};