export const Container = ({
	children,
	paddingY,
	width
}) => {
	const classList = ['container', 'mx-auto', 'px-4', 'md:px-5'];

	switch (width) {
		case 'small':
			classList.push('max-w-md');
			break;

		case 'medium':
			classList.push('max-w-lg');
			break;

		case 'large':
			classList.push('max-w-5xl');
			break;

		case 'extra-large':
			classList.push('max-w-7xl');
			break;

		default:
			break;
	}

	switch (paddingY) {
		case 'small':
			classList.push('py-3');
			break;

		case 'medium':
			classList.push('py-5');
			break;

		case 'large':
			classList.push('py-7');
			classList.push('md:py-10');
			break;

		case 'extra-large':
			classList.push('py-10');
			classList.push('md:py-20');
			break;

		default:
			break;
	}

	return (
		<div className={classList.join(' ')}>
			{children}
		</div>
	);
};

Container.defaultProps = {
	paddingY: 'extra-large',
	width: 'extra-large'
};