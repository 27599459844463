export const Button = ({
	children,
	link,
	onClick,
	title,
	variant
}) => {
	if (link) {
		return (
			<a href={link} className="block md:inline-block bg-cyan-500 hover:bg-cyan-600 py-4 px-6 text-center text-white rounded" onClick={onClick} title={title}>{children}</a>
		);
	}

	return (
		<button className="block w-full bg-cyan-500 hover:bg-cyan-600 py-4 px-6 text-center text-white rounded" onClick={onClick} title={title}>{children}</button>
	);
};