import logo from './../../media/digitellie-wordmark.svg';

export const Header = () => {
	return (
		<header>
			<div className="container max-w-7xl mx-auto px-5">
				<div className="flex g-2">
					<div className="my-4">
						<a href="/" title="Link to Digitellie homepage">
							<img className="h-7 md:h-10" src={logo} alt="Digitellie Web Development" />
						</a>
					</div>
				</div>
			</div>
		</header>
	);
};