export const Card = ({
	title,
	content
}) => {
	return (
		<div className="bg-white shadow md:p-8 p-5 rounded">
			{title &&
				<h3 className="text-2xl mb-4">{title}</h3>
			}
			{content && 
				<p className="text-lg">{content}</p>
			}
		</div>
	);
};