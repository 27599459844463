import { useRef, useState } from 'react';

import './styles/main.scss';

import { Header } from './components/Header/Header';
import { Hero } from './components/Hero/Hero';
import { Container } from './components/Container/Container';
import { Row } from './components/Row/Row';
import { Card } from './components/Card/Card';
import { Footer } from './components/Footer/Footer';
import { Button } from './components/Button/Button';

function App() {
	const [formSubmitted, setFormSubmitted] = useState(null);
	const form = useRef(null);
	const firstName = useRef(null);
	const lastName = useRef(null);
	const email = useRef(null);

	const submitForm = (e) => {
		e.preventDefault();

		fetch('//api.digitellie.com/contact-form/', {
			method: 'POST',
			body: JSON.stringify({
				first_name: firstName.current.value,
				last_name: lastName.current.value,
				email: email.current.value
			})
		}).then(response => response.json())
		.then(data => {
			if (data.code !== 200) {
				setFormSubmitted(false);
			} else {
				setFormSubmitted(true);
			}
		}).catch(() => {
			setFormSubmitted(false);
		});
	};

	return (
		<div className="app ">
			<Header />
			<div className="page leading-loose">
				<Hero
					title={<span>Custom web design and development for your business needs.</span>}
					content={<p className="leading-loose">In today's market, a strong online presence with a positive user experience is no longer optional. If the thought of how to get started seems overwhelming, we're here to help.</p>}
					ctaLabel="Contact Us"
					ctaLink="#contact" />
				<Container paddingY="extra-large">
					<h2 className="text-4xl md:text-5xl font-bold mb-5 md:text-center">What you'll get working with us:</h2>
					<p className="md:text-center text-lg">We're your partner in building creative online solutions, so you can stay focused on what you do best. </p>
					<div className="mt-10">
						<Row colsMd="3">
							<Card
								title="Web Development"
								content="We're your partner in building creative online solutions, so you can stay focused on what you do best." />
							<Card 
								title="Digital Marketing" 
								content="From paid ads to search engine optimization, we can help meet your digital marketing goals." />
							<Card title="Quick time to market"
								content="We work quickly and effeciently to provide your solution in a timely and cost-effective manner." />
						</Row>
					</div>
				</Container>
				<div className="bg-gray-50" id="contact">
					<Container paddingY="extra-large">
						<h2 className="text-4xl md:text-5xl font-bold text-center">Let's work together.</h2>
						<div className="container mx-auto max-w-md mt-10 bg-white p-5 rounded shadow">
							{!formSubmitted && 
								<form ref={form} onSubmit={(e) => submitForm(e)}>
									<div className="mb-5">
										<label className="text-gray-700" htmlFor="first_name">First Name <span className="text-red-800">*</span></label>
										<input className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" id="first_name" type="text" required ref={firstName} />
									</div>
									<div className="mb-5">
										<label className="text-gray-700" htmlFor="last_name">Last Name <span className="text-red-800">*</span></label>
										<input className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" id="last_name" type="text" required ref={lastName} />
									</div>
									<div className="mb-5">
										<label className="text-gray-700" htmlFor="email">Email <span className="text-red-800">*</span></label>
										<input className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" id="email" type="email" required ref={email} />
									</div>
									<div className="w-full">
										<Button>Contact Us</Button>
									</div>
									{formSubmitted === false &&
										<div className="bg-red-200 p-4 rounded mt-5" role="alert">There has been an error submitting you form. Please make sure all the required fields have been addressed.</div>
									}
								</form>
							}
							{formSubmitted &&
								<div className="bg-lime-100 p-5 rounded text-center">
									<h3 className="text-2xl font-bold mb-3">Thank you for reaching out!</h3>
									<p>We'll be in touch shortly to start the conversation.</p>
								</div>
							}
						</div>
					</Container>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default App;
