import { Button } from './../Button/Button';

export const Hero = ({
	title,
	content,
	ctaLabel,
	ctaLink
}) => {
	return (
		<div className="relative py-10 md:py-32">
			<div className="absolute z-0 w-full h-full top-0 left-0 bg-gray-50"></div>
			<div className="relative z-10 container max-w-4xl mx-auto px-5 md:text-center">
						{title &&
							<h1 className="text-4xl lg:text-6xl font-bold">{title}</h1>
						}
						{content &&
							<div className="text-xl my-4">
								{content}
							</div>
						}
						{ctaLabel && ctaLink &&
							<div>
								<Button link={ctaLink}>{ctaLabel}</Button>
							</div>
						}
			</div>
		</div>
	);
};